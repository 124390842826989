const STRINGS = {
  'sv-SE': {
    timeZone: 'Europe/Stockholm',
    its: 'det är',
    morning: 'Gomorron',
    afternoon: `Go'dag`,
    evening: `Go'kväll`,
    night: 'God natt',
    sunny: 'och soligt väder',
    clouds: 'och molnigt väder',
    rain: 'och regnigt väder',
    thunderstorm: 'och regnet står som spön i backen!',
    snow: 'och det snöar',
    drizzle: 'och det duggregnar',
    other: 'och livet leker!', // and life is amazing
  },
  'pt-BR': {
    timeZone: 'America/Sao_Paulo',
    its: 'são',
    morning: 'Bom dia',
    afternoon: 'Boa tarde',
    evening: 'Boa noite',
    night: 'Boa noite',
    sunny: 'e é clima de praia',
    clouds: 'clima para relaxar',
    rain: 'e não é clima de praia',
    thunderstorm: 'e com certeza não tá com clima de praia',
    snow: 'e algo não está certo ❄️',
    drizzle: 'e está garoando',
    other: 'e tá rolando algo diferente',
  },
  'is-IS': {
    timeZone: 'Atlantic/Reykjavik',
    its: 'klukkan er',
    morning: 'Góðan daginn',
    afternoon: `Góðan daginn`,
    evening: `Góða kvöldið`,
    night: 'Góða nótt',
    sunny: 'og sól úti',
    clouds: 'og svoldið skyjað',
    rain: 'og rignir úti',
    thunderstorm: 'og brjálað veður!',
    snow: 'og það snjóar',
    drizzle: 'og það falla nokkrir dropar',
    other: 'og lífið er ljuft!',
  },
}

const getLocalizedTime = (time, language) => {
  const { timeZone } = STRINGS[language]
  const options = { hour: 'numeric', minute: 'numeric', timeZone }
  return Intl.DateTimeFormat(language, options).format(time)
}

const getDayPeriod = localizedTime => {
  const [hour] = localizedTime.split(':')
  if (hour >= 22) return 'night'
  if (hour >= 17) return 'evening'
  if (hour >= 12) return 'afternoon'
  if (hour >= 5) return 'morning'
  return 'night'
}

const getPhrase = (time, language, weather) => {
  if (!STRINGS[language]) return ''

  const { its, ...localized } = STRINGS[language]
  const localizedTime = getLocalizedTime(time, language)
  const greeting = localized[getDayPeriod(localizedTime)]
  const weatherPhrase = weather ? STRINGS[language][weather] || STRINGS[language].other : ''
  return `${greeting}, ${its} ${localizedTime} ${weatherPhrase}`
}

export default getPhrase
