import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import useUIContext from 'context/ui'
import ViewportEnterEffect from 'components/motion/viewport-enter-effect'
import AspectRatio from 'components/ui/aspect-ratio'
import Image from 'components/ui/image'
import Clickable from 'components/ui/clickable'

import useWeather from './useWeather'
import getPhrase from './getPhrase'
import * as s from './Office.module.css'

const cn = classNames.bind(s)

const Office = ({ now, ...data }) => {
  const { weather } = useWeather(data.office_coordinates)
  const isScrollRigEnabled = useUIContext(s => s.isScrollRigEnabled)

  return (
    <div className={cn('office')}>
      <AspectRatio ratio={1} desktopRatio={1} className={cn('officeImage')}>
        <ViewportEnterEffect threshold={0.4} disabled={isScrollRigEnabled} effect="zoomOutImgMobile">
          <Image alt={data.office_image?.alt} image={data.office_image?.gatsbyImageData} className={cn('minZoom')} />
        </ViewportEnterEffect>
      </AspectRatio>
      <div className={cn('officeContent')}>
        <div className={cn('officeTitle')}>{data.office_title}</div>
        <div className={cn('officeAddress')}>
          <div>{data.office_address_1}</div>
          <div>{data.office_address_2}</div>
        </div>
        {data?.office_email?.url && (
          <Clickable to={data.office_email.url} className={cn('officeEmail')}>
            {data.office_email.url.replace('mailto:', '')}
          </Clickable>
        )}
        <div className={cn('officeGreeting')}>{getPhrase(now, data.office_language, weather)}</div>
      </div>
    </div>
  )
}

Office.propTypes = {
  now: PropTypes.number,
}

export default Office
