import React, { forwardRef } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import replaceQuote from 'lib/replaceQuote'

const get = img => img?.src || img?.url || img || ''

const Image = forwardRef(({ src, alt, image, ...props }, ref) => {
  if (!image) {
    const url = get(src)
    if (!url) return null
    return <img crossOrigin="anonymous" ref={ref} src={url} alt={replaceQuote(alt)} {...props} />
  }
  return <GatsbyImage crossOrigin="anonymous" image={image} alt={alt ? replaceQuote(alt) : ''} ref={ref} {...props} />
})

Image.displayName = 'Image'

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image: PropTypes.object,
  alt: PropTypes.string,
}

export default Image
