import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'prismic'
import { prismicRepo } from '../../prismic-configuration'

import ContactPage from 'views/contact/ContactPage'

export const query = graphql`
  query ContactPageQuery {
    prismicContact(uid: { eq: "contact" }, lang: { eq: "en-us" }) {
      _previewable
      uid
      data {
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          url
        }
        drawing_mode
        drawing_title {
          text
        }
        drawing_description {
          richText
        }
        offices {
          office_title
          office_image {
            alt
            url
            gatsbyImageData
          }
          office_address_1
          office_address_2
          office_email {
            url
          }
          office_language
          office_coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`

export default withPrismicPreview(ContactPage, [
  {
    repositoryName: prismicRepo,
    linkResolver,
  },
])
