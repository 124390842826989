import { useState, useEffect } from 'react'

const API_KEY = process.env.GATSBY_OPENWEATHER_API_KEY

const fetchJSON = (...args) => window?.fetch(...args).then(res => res?.json())

const useWeather = ({ latitude, longitude }) => {
  const [weather, setWeather] = useState(null)
  const [loading, setLoading] = useState(false)

  const base = 'https://api.openweathermap.org/data/2.5/weather'
  const url = base + `?lat=${latitude}&lon=${longitude}&appid=${API_KEY}`

  const fetchWeather = async () => {
    setLoading(true)
    const res = await fetchJSON(url)
    setWeather(res?.weather?.[0]?.main.toLowerCase())
    setLoading(false)
  }

  useEffect(() => {
    fetchWeather()
  }, [latitude, longitude])

  return { weather, loading }
}

export default useWeather
