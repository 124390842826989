import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useInterval } from 'react-use'
import { RichText } from 'prismic'

import useUIContext from 'context/ui'
import Layout from 'layouts'
import SEO from 'components/seo'
import { Container, Grid } from 'components/ui/layout'
import Footer from 'components/ui/footer'
import LettersAnimation from 'components/motion/letters-animation/LettersAnimation'

import Office from './components/office'

import * as s from './ContactPage.module.css'

const cn = classNames.bind(s)

const getTime = () => new Date().getTime()

const ONE_MINUTE = 60 * 1000

const useTime = (rate = ONE_MINUTE) => {
  const [now, setNow] = useState(getTime)
  useInterval(() => {
    setNow(getTime)
  }, rate)
  return now
}

const Offices = ({ offices = [] }) => {
  const now = useTime()
  return (
    <Grid>
      {offices.map(data => (
        <Office now={now} key={data.office_title} {...data} />
      ))}
    </Grid>
  )
}

Offices.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.object),
}

const ContactPage = ({ data }) => {
  const isScrollRigEnabled = useUIContext(s => s.isScrollRigEnabled)
  const page = data?.prismicContact?.data
  if (!page) return null

  const metaTitle = page?.page_meta_title
  const metaDescription = page?.page_meta_description
  const metaThumbnail = page?.page_meta_thumbnail
  const offices = page?.offices || []

  return (
    <Layout footer={false} labCTA={false}>
      <SEO title={metaTitle} description={metaDescription} thumbnail={metaThumbnail} />

      <Container className={cn('margin')}>
        <div className={cn('wrapper')}>
          <LettersAnimation text={page.drawing_title.text} className={cn('title')} />

          <div className={cn('description')}>
            <div>{RichText.render(page.drawing_description?.richText)}</div>
          </div>
        </div>
      </Container>
      <Footer
        paintMode="none"
        className={cn('footer')}
        renderDrawingWrapper={false}
        separator={<div className={cn('separator')} />}
      />
      <Container>
        <Offices offices={offices} />
      </Container>

      {isScrollRigEnabled && <div className={cn('spacer')} />}
    </Layout>
  )
}

export default ContactPage
